import "../Pedido.css";

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Legenda } from "../../../Components/Legenda/Legenda";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { toggleActive, toggleList } from "../../../Helpers/toggle";

import { fetchPedidos, fetchProgColetas } from "../Helpers/fetch";
import { capitalize } from "../../../Helpers/string";

export const Pedidos = () => {
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [dia, setDia] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [navActive, setNav] = useState<boolean[]>([true, false]);
  const [active, setActive] = useState<boolean>(false);
  const navegacao = ["Pedidos", "Programação"];

  const {
    status: pedidoStatus,
    data: pedidosData,
    isLoading: loadingPedidos,
  } = useQuery({
    queryKey: ["pedidos"],
    queryFn: fetchPedidos,
  });
  useEffect(() => {
    if (pedidoStatus === "success") setPedidos(pedidosData);
  }, [pedidoStatus, pedidosData]);

  const {
    status: progStatus,
    data: progData,
    isLoading: progLoading,
  } = useQuery({
    queryKey: ["progColetas", dia],
    queryFn: () => fetchProgColetas(dia),
    enabled: !!dia,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleStatusChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <select name="status" id="status">
                <option value="todos">Todos</option>
                <option value="fat-pendente">Faturamento Pendente</option>
                <option value="faturado">Faturado</option>
                <option value="agendado">Agendado</option>
                <option value="coleta-parcial">Coleta Parcial</option>
                <option value="coletado">Coletado</option>
                <option value="pag-pendente">Pagamento Pendente</option>
                <option value="cancelado">Cancelado</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleSearchChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <input
                type="text"
                name="buscador"
                id="buscador"
                className="search"
              />
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className={`edit ${!navActive[1] && "unreachable"}`}
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <Legenda
              active={active}
              onToggle={() => toggleActive(setActive, active)}
              items={{
                "fat-pendente": "Faturamento Pendente",
                faturado: "Faturado",
                agendado: "Agendado",
                confirmado: "Confirmado",
                "coleta-parcial": "Coleta Parcial",
                coletado: "Coletado",
                "pag-pendente": "Pagamento Pendente",
                cancelado: "Cancelado",
              }}
            />
            <section className={`focus ${!navActive[0] && "unreachable"}`}>
              {loadingPedidos ? (
                <Spinner active />
              ) : pedidos.length > 0 ? (
                pedidos.map((pedido) => {
                  return (
                    <a
                      href={`pedidos/${pedido.numero_pedido}`}
                      key={pedido.numero_pedido}
                    >
                      <Card
                        class={pedido.status}
                        title={`Pedido ${pedido.numero_pedido}`}
                        subtitle={pedido.nome}
                        content={
                          <article>
                            <span className="subtitle">Endereço:</span>
                            <br />
                            {`${capitalize(pedido.rua)},
                            ${pedido.numero},
                            ${capitalize(pedido.bairro)},
                            ${capitalize(pedido.cidade.slice(0, -5))},
                            ${pedido.sigla}`}
                          </article>
                        }
                      />
                    </a>
                  );
                })
              ) : (
                <article>Nâo há pedidos cadastrados no sistema!</article>
              )}
            </section>
            <section className={`focus prog ${!navActive[1] && "unreachable"}`}>
              {progLoading ? (
                <Spinner active />
              ) : progStatus === "success" ? (
                progData.map((prog) => {
                  return (
                    <section key={prog.programacao} className="programacao">
                      <h2 className="title">{prog.programacao}</h2>
                      {prog.coletas.map((coleta) => {
                        return (
                          <a
                            href={`pedidos/${coleta.num_pedido_mae}`}
                            key={coleta.numero_pedido}
                          >
                            <article className={`coletas ${coleta.status}`}>
                              <h3>{`Coleta ${coleta.numero_pedido}`}</h3>
                            </article>
                          </a>
                        );
                      })}
                    </section>
                  );
                })
              ) : (
                <article></article>
              )}
            </section>
          </section>
          <section className="nav">
            <section className="tabs">
              {navegacao.map((text, idx) => {
                return (
                  <button
                    key={text}
                    onClick={() => toggleList(setNav, navegacao, text)}
                    className={`tab ${navActive[idx] ? "in" : "out"}`}
                    type="button"
                  >
                    {text}
                  </button>
                );
              })}
            </section>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );

  function handleStatusChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const status = event.currentTarget.status.value;

    if (pedidosData) {
      if (status === "todos") return setPedidos(pedidosData);

      const filtrados = pedidosData.filter((pedido) => {
        return pedido.status === status;
      });
      return setPedidos(filtrados);
    }
  }

  function handleSearchChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const buscador = event.currentTarget.buscador.value;

    if (pedidosData) {
      if (buscador === "") return setPedidos(pedidosData);

      if (parseInt(buscador)) {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.numero_pedido.toString().includes(buscador);
          })
        );
      } else {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.nome.toLowerCase().includes(buscador.toLowerCase());
          })
        );
      }
    }
  }

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setDia(event.currentTarget.dia.value);
  }
};
