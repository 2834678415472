import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { fetchColeta, fetchPalletsColeta } from "../Helpers/fetch";

import { pcp } from "../../../Configs/Axios";
import { usuario } from "../../../Configs/Usuario";
import { AxiosError } from "axios";

export const Coleta = () => {
  const { numero_coleta } = useParams();
  const [spin, setSpin] = useState<boolean>(false);
  const [entrega, setEntrega] = useState<boolean>(false);

  const {
    status: statusColeta,
    data: coleta,
    isLoading: loadingColeta,
  } = useQuery({
    queryKey: ["coleta", numero_coleta],
    queryFn: () => fetchColeta(numero_coleta!),
    enabled: !!numero_coleta,
  });
  const {
    status: statusPallets,
    data: pallets,
    isLoading: loadingPallets,
  } = useQuery({
    queryKey: ["coletas", numero_coleta],
    queryFn: () => fetchPalletsColeta(numero_coleta!),
    enabled: !!numero_coleta,
  });
  useEffect(() => {
    function validarEntrega() {
      if (coleta && pallets && pallets.length > 0) {
        coleta.produtos.forEach((produto) => {
          const palletsProduto = pallets.filter(
            (pallet) => pallet.descricao === produto.descricao
          );

          let soma = 0;
          palletsProduto.forEach((pallet) => {
            soma += pallet.quantidade;
          });

          if (produto.quantidade !== soma) setEntrega(false);
        });

        setEntrega(true);
      }
    }

    if (statusColeta === "success" && statusPallets === "success")
      validarEntrega();
  }, [statusColeta, statusPallets, coleta, pallets]);

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {loadingColeta ? (
              <Spinner active />
            ) : statusColeta === "success" ? (
              <section id="card-resumo">
                <Card
                  class=""
                  title={`Entrega ${new Date(coleta.entrega).toLocaleDateString(
                    "pt-br",
                    {
                      timeZone: "UTC",
                    }
                  )}`}
                  subtitle={`Coleta ${coleta.numero_pedido}`}
                  content={coleta.produtos.map((produto) => {
                    return (
                      <section key={produto.descricao}>
                        <strong style={{ fontWeight: "bold" }}>
                          {produto.descricao}
                        </strong>{" "}
                        - {produto.quantidade}
                        <br />
                        <br />
                      </section>
                    );
                  })}
                  icons={[
                    {
                      href: `/logistica/estoque_reservado/${coleta.num_pedido_mae}`,
                      icon: "21d0",
                    },
                  ]}
                />
                <button
                  id={coleta.codigo.toString()}
                  onClick={entregar}
                  type="button"
                  className={`${!entrega && "disabled"} ${
                    usuario.nivel === "visualizador" && "unreachable"
                  }`}
                  disabled={!entrega}
                >
                  Entregar
                </button>
              </section>
            ) : (
              <article>Não há informações de coleta!</article>
            )}
            <section className="focus">
              {loadingPallets ? (
                <Spinner active />
              ) : statusPallets === "success" && pallets.length > 0 ? (
                pallets.map((pallet) => {
                  return (
                    <article key={pallet.id} className="ordens">
                      <Card
                        class=""
                        title={`T${pallet.turno} ${pallet.referencia}/${
                          pallet.sequencial
                        } ${pallet.descricao.split("MM ").pop()}`}
                        subtitle={new Date(
                          pallet.programacao
                        ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                        content={
                          <article>
                            <span className="subtitle">{pallet.descricao}</span>
                            <br />
                            Quantidade: {pallet.quantidade}
                          </article>
                        }
                      />
                      <button
                        id={pallet.id.toString()}
                        className={`${
                          pallet.status === "entregue" && "disabled"
                        } ${usuario.nivel === "visualizador" && "unreachable"}`}
                        onClick={remover}
                        disabled={pallet.status === "entregue"}
                      >
                        Remover
                      </button>
                    </article>
                  );
                })
              ) : (
                <article>Não há informações de pallets!</article>
              )}
            </section>
          </section>
        </section>
        <Atalhos />
        <Spinner active={spin} />
      </section>
    </>
  );

  async function entregar(event: SyntheticEvent) {
    const codigo = event.currentTarget.id;
    setSpin(true);

    try {
      await pcp.post(`/logistica/pedidos/coleta/entregar/${codigo}`);

      window.location.reload();
      alert("Entrega realizada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function remover(event: SyntheticEvent) {
    const id = event.currentTarget.id;
    setSpin(true);

    try {
      await pcp.patch(`/logistica/pallet/remover_coleta/${id}`);

      window.location.reload();
      alert("Pallet removido da reserva com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
