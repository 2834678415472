import "../Pedido.css";

import { SyntheticEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Legenda } from "../../../Components/Legenda/Legenda";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { toggleActive } from "../../../Helpers/toggle";
import { fetchColetas, fetchPedido } from "../Helpers/fetch";

import { pcp } from "../../../Configs/Axios";
import { AxiosError } from "axios";

export const ResumoPedido = () => {
  const [spin, setSpin] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [colActive, setColActive] = useState<boolean>(false);
  const { numero_pedido } = useParams();

  const {
    status: statusPedido,
    data: pedido,
    isLoading: loadingPedido,
  } = useQuery({
    queryKey: ["pedido", numero_pedido],
    queryFn: () => fetchPedido(numero_pedido!),
    enabled: !!numero_pedido,
  });
  const {
    status: statusColeta,
    data: coletas,
    isLoading: loadingColetas,
  } = useQuery({
    queryKey: ["coletas", numero_pedido],
    queryFn: () => fetchColetas(numero_pedido!),
    enabled: !!numero_pedido,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          {loadingPedido ? (
            <Spinner active />
          ) : statusPedido === "success" ? (
            <section id="aux">
              <section id="card-resumo">
                <Card
                  class={pedido.status}
                  title={`Pedido ${pedido.numero_pedido}`}
                  subtitle="Produtos:"
                  content={pedido.produtos.map((produto) => {
                    return (
                      <section key={produto.descricao}>
                        {produto.descricao}: <br />
                        &emsp;Qtd Total: {produto.qtdTotal} <br />
                        &emsp;Qtd Pendente: {produto.qtdPendente} <br />
                        &emsp;Qtd Parcial: {produto.qtdParcial}
                        <br />
                        <br />
                      </section>
                    );
                  })}
                  icons={[{ href: "/logistica/pedidos", icon: "21d0" }]}
                />
                <button
                  className={`add-coleta ${
                    pedido.status !== "faturado" &&
                    pedido.status !== "agendado" &&
                    pedido.status !== "coleta-parcial" &&
                    "disabled"
                  }`}
                  disabled={
                    pedido.status !== "faturado" &&
                    pedido.status !== "agendado" &&
                    pedido.status !== "coleta-parcial"
                  }
                  onClick={() => toggleActive(setColActive, colActive)}
                >
                  Adicionar Coleta
                </button>
              </section>
            </section>
          ) : (
            <article>Não há informações de pedidos!</article>
          )}
          <section id="main-content">
            <Legenda
              active={active}
              onToggle={() => toggleActive(setActive, active)}
              items={{
                agendado: "Agendado",
                confirmado: "Confirmado",
                coletado: "Coletado",
                cancelado: "Cancelado",
              }}
            />
            <section className="focus">
              {loadingColetas ? (
                <Spinner active />
              ) : statusColeta === "success" && coletas.length > 0 ? (
                coletas.map((coleta) => {
                  return (
                    <section key={coleta.codigo} className="ped">
                      <Card
                        class={coleta.status}
                        title={`Entrega ${new Date(
                          coleta.entrega
                        ).toLocaleDateString("pt-br", {
                          timeZone: "UTC",
                        })}`}
                        subtitle={`Coleta ${coleta.numero_pedido}`}
                        content={coleta.produtos.map((produto) => {
                          return (
                            <section key={produto.descricao}>
                              <strong style={{ fontWeight: "bold" }}>
                                {produto.descricao}
                              </strong>{" "}
                              - {produto.quantidade}
                              <br />
                              <br />
                            </section>
                          );
                        })}
                      />
                      <section className="actions">
                        <button
                          id={coleta.codigo.toString()}
                          onClick={handleDelete}
                          className={`${
                            coleta.status !== "agendado" &&
                            coleta.status !== "pag-pendente" &&
                            "disabled"
                          }`}
                          disabled={
                            coleta.status !== "agendado" &&
                            coleta.status !== "pag-pendente"
                          }
                        >
                          Deletar
                        </button>
                        <button
                          id={coleta.codigo.toString()}
                          onClick={handleConfirm}
                          className={`${
                            coleta.status !== "agendado" && "disabled"
                          }`}
                          type="button"
                        >
                          Confirmar
                        </button>
                      </section>
                    </section>
                  );
                })
              ) : (
                <article>Não há informações de coletas!</article>
              )}
            </section>
          </section>
        </section>
        <Atalhos />
        <section className={`edicoes ${colActive && "active"}`}>
          <section>
            <h2>Adicionar Coleta:</h2>
            <form onSubmit={adicionarColeta} className="edit add-coleta">
              <button
                aria-hidden
                className="unreachable"
                type="submit"
                disabled
              ></button>
              <label htmlFor="entrega">Entrega:</label>
              <input type="date" name="entrega" id="entrega" />
              <fieldset className="edit add-coleta">
                <legend>Produtos:</legend>

                {pedido && pedido.produtos && pedido.produtos.length > 0 ? (
                  pedido.produtos.map((produto) => {
                    return (
                      <article key={produto.descricao}>
                        <h3>{produto.descricao}</h3>
                        <input
                          defaultValue={produto.qtdPendente}
                          type="number"
                          name={produto.descricao}
                          className="quantidade"
                          min={0}
                          max={produto.qtdPendente}
                        />
                      </article>
                    );
                  })
                ) : (
                  <article></article>
                )}
              </fieldset>
              <button type="submit">Adicionar</button>
            </form>
          </section>
          <button onClick={() => toggleActive(setColActive, colActive)}>
            <span className="icon">&#x2715;</span>
          </button>
        </section>
        <Spinner active={spin} />
      </section>
    </>
  );

  async function handleDelete(event: SyntheticEvent) {
    event.preventDefault();
    setSpin(true);

    try {
      await pcp.delete(`/logistica/pedidos/coleta/${event.currentTarget.id}`);

      window.location.reload();
      alert("Coleta deletada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
  async function adicionarColeta(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    const target = event.currentTarget;

    const produtos: Produto[] = [];
    if (pedido) {
      pedido.produtos.forEach((produto) => {
        if (target[`${produto.descricao}`].value > 0)
          produtos.push({
            descricao: produto.descricao,
            quantidade: target[`${produto.descricao}`].value,
          });
      });

      const corpoColeta = {
        entrega: target.entrega.value,
        codigo_pedido: pedido.codigo,
        numero_pedido: pedido.numero_pedido,
        produtos: produtos,
      };

      try {
        const novaColeta = await pcp.post(
          "/logistica/pedidos/coleta",
          corpoColeta
        );
        if (novaColeta.status !== 201) {
          setSpin(false);
          return alert("Dados inválidos!");
        }

        window.location.reload();
        return alert("Coleta adicionada com sucesso!");
      } catch (error) {
        setSpin(false);

        return alert(`Ocorreu um erro ao adicionar a coleta: ${error}`);
      }
    }
  }

  async function handleConfirm(event: SyntheticEvent) {
    event.preventDefault();
    setSpin(true);

    try {
      await pcp.patch(
        `/logistica/pedidos/coleta/confirmar/${event.currentTarget.id}`
      );

      window.location.reload();
      alert("Coleta confirmada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
