import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { pcp } from "../../../Configs/Axios";
import { usuario } from "../../../Configs/Usuario";

import { fetchAcabamento } from "../Helpers/fetch";
import { AxiosError } from "axios";

export const Acabamento = () => {
  const navigate = useNavigate();
  const { dia } = useParams();
  const [spin, setSpin] = useState<boolean>(false);

  const { status, data, isLoading } = useQuery({
    queryKey: ["acabamento", dia],
    queryFn: () => fetchAcabamento(dia!),
    enabled: !!dia,
  });
  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section className="focus">
              {isLoading ? (
                <Spinner active />
              ) : status === "success" && data.length > 0 ? (
                data.map((pallet) => {
                  return (
                    <article key={pallet.id} className="ordens">
                      <button
                        style={{
                          background: "none",
                          boxShadow: "none",
                          textAlign: "left",
                        }}
                        onClick={() => navigate(`pallet/${pallet.id}`)}
                      >
                        <Card
                          class=""
                          title={`T${pallet.turno} ${pallet.referencia}/${
                            pallet.sequencial
                          } ${pallet.descricao.split("MM ").pop()}`}
                          subtitle={new Date(
                            pallet.programacao
                          ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                          content={
                            <article>
                              <span className="subtitle">
                                {pallet.descricao}
                              </span>
                              <br />
                              Quantidade: {pallet.quantidade}
                            </article>
                          }
                        />
                      </button>
                      <button
                        id={pallet.id.toString()}
                        className={`${
                          pallet.status !== "acabamento" && "disabled"
                        } ${usuario.nivel === "visualizador" && "unreachable"}`}
                        onClick={avancar}
                        disabled={pallet.status !== "acabamento"}
                      >
                        Avançar
                      </button>
                    </article>
                  );
                })
              ) : (
                <article>Nenhum painel a acabar!</article>
              )}
            </section>
          </section>
        </section>
        <Spinner active={spin} />
        <Atalhos />
      </section>
    </>
  );

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    navigate(`/logistica/acabamento/${event.currentTarget.dia.value}`);
  }

  async function avancar(event: SyntheticEvent) {
    const id = event.currentTarget.id;
    setSpin(true);

    try {
      await pcp.patch(`/logistica/acabamento/${id}`);

      window.location.reload();
      alert("Pallet avançado para próxima etapa com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
