import { RouteObject } from "react-router-dom";

import { RotasLogadas } from "../Auth/Components/Logged";

import { SplashProducao } from "./Pages/Splash";
import { ProducaoBaterias } from "./Pages/ProdBaterias";
import { OrdensProducao } from "./Pages/OrdensProd";
import { EstoqueProduzido } from "./Pages/EstoqueProd";
import { Reparo } from "./Pages/Reparo";

export const producaoRouter: RouteObject[] = [
  {
    path: "/producao",
    element: <RotasLogadas />,
    children: [
      {
        path: "",
        element: <SplashProducao />,
      },
      {
        path: "baterias",
        element: <ProducaoBaterias />,
      },
      {
        path: "ordens",
        children: [
          {
            path: ":dia/:turno/:status",
            element: <OrdensProducao />,
          },
        ],
      },
      {
        path: "estoque_produzido",
        children: [
          {
            path: ":dia",
            element: <EstoqueProduzido />,
          },
        ],
      },
      {
        path: "reparo",
        children: [
          {
            path: ":dia",
            element: <Reparo />,
          },
        ],
      },
    ],
  },
];
