import "../Producao.css";

import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toggleActive, toggleList } from "../../../Helpers/toggle";

import {
  fetchConsumo,
  fetchOrdens,
  fetchProdutos,
  fetchResumo,
} from "../Helpers/fetch";

import { Testes } from "../Components/Testes";
import { Card } from "../../../Components/Card/Card";
import { Apontamento } from "../Components/Apontamento";
import { Legenda } from "../../../Components/Legenda/Legenda";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { pcp } from "../../../Configs/Axios";
import { usuario } from "../../../Configs/Usuario";

import { fetchTracos } from "../../Qualidade/Helpers/fetch";
import { AxiosError } from "axios";
import { Adicionar } from "../Components/Adicionar";

export const OrdensProducao = () => {
  const navigate = useNavigate();
  const { dia, turno, status } = useParams();

  const [ordens, setOrdens] = useState<Ordem[]>([]);
  const [spin, setSpin] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [apont, setApont] = useState<boolean>(false);
  const [testes, setTestes] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [tipo, setTipo] = useState<string>("");

  const [navActive, setNav] = useState<boolean[]>([true, false, false]);
  const [active, setActive] = useState<boolean>(false);
  const navegacao = ["Ordens", "Consumo", "Resumo"];

  const {
    status: statusTraco,
    data: tracoData,
    isLoading: tracoLoading,
  } = useQuery({
    queryKey: ["tracos"],
    queryFn: fetchTracos,
  });

  const {
    status: ordenStatus,
    data: ordensData,
    isLoading: ordensLoading,
  } = useQuery({
    queryKey: ["ordens", dia],
    queryFn: () => fetchOrdens(dia!),
    enabled: !!dia,
  });
  useEffect(() => {
    if (ordenStatus === "success" && ordensData.length > 0) {
      let filtrados;
      if (status === "todos") {
        filtrados = ordensData.filter(
          (ordem) => ordem.turno === parseInt(turno!)
        );
      } else {
        filtrados = ordensData
          .filter((ordem) => ordem.status === status!)
          .filter((ordem) => ordem.turno === parseInt(turno!));
      }
      setOrdens(filtrados);
    }
  }, [ordenStatus, ordensData, status, turno]);

  const { data: proData } = useQuery({
    queryKey: ["produtos"],
    queryFn: fetchProdutos,
    enabled: !!edit,
  });

  const {
    status: statusResumo,
    data: dataResumo,
    isLoading: resumoLoading,
  } = useQuery({
    queryKey: ["resumo", dia],
    queryFn: () => fetchResumo(dia!),
    enabled: !!dia,
  });

  const {
    status: statusConsumo,
    data: dataConsumo,
    isLoading: consumoLoading,
  } = useQuery({
    queryKey: ["consumo", dia],
    queryFn: () => fetchConsumo(dia!),
    enabled: !!dia,
  });

  return (
    <>
      <Navegacao ativo={[true, false, false]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <select defaultValue={status} name="status" id="status">
                <option value="todos">Todos</option>
                <option value="a-fazer">A Fazer</option>
                <option value="em-andamento">Em Andamento</option>
                <option value="concluido">Concluído</option>
                <option value="concluido-parcialmente">
                  Concluído Parcialmente
                </option>
                <option value="nao-realizado">Não Realizado</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <select defaultValue={turno} name="turno" id="turno">
                <option value="1">1º Turno</option>
                <option value="2">2º Turno</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <Legenda
              active={active}
              onToggle={() => toggleActive(setActive, active)}
              items={{
                "a-fazer": "A Fazer",
                "em-andamento": "Em Andamento",
                concluido: "Concluído",
                "concluido-parcialmente": "Concluído Parcialmente",
                "nao-realizado": "Não Realizado",
              }}
            />
            <section className={`focus ${!navActive[0] && "unreachable"}`}>
              {ordensLoading ? (
                <Spinner active />
              ) : ordenStatus === "success" && ordensData.length > 0 ? (
                ordens.map((ordem) => {
                  return (
                    <article key={ordem.id} className="ordens">
                      <Card
                        class={ordem.status}
                        title={`${ordem.bateria}/${ordem.sequencial}`}
                        subtitle={`${ordem.turno}T`}
                        content={
                          <article>
                            <span className="subtitle">{ordem.descricao}</span>
                            <br />
                            Quantidade: {ordem.quantidade}
                          </article>
                        }
                      />
                      <section className="actions">
                        <button
                          id={ordem.id.toString()}
                          onClick={iniciar}
                          className={`${
                            (ordem.status !== "a-fazer" ||
                              new Date(dia!) > new Date()) &&
                            "disabled"
                          } ${
                            (usuario.nivel === "visualizador" ||
                              !usuario.qualidade) &&
                            "unreachable"
                          } unreachable`}
                          disabled={
                            ordem.status !== "a-fazer" ||
                            new Date(dia!) > new Date()
                          }
                          type="button"
                        >
                          Iniciar
                        </button>
                        <button
                          onClick={() => {
                            toggleActive(setTestes, testes);
                            setId(ordem.id);
                            setTipo(ordem.descricao.split("MM ").pop()!);
                          }}
                          className={`${
                            new Date(dia!) > new Date() && "disabled"
                          } ${
                            (usuario.nivel === "visualizador" ||
                              !usuario.qualidade) &&
                            "unreachable"
                          }`}
                          disabled={new Date(dia!) > new Date()}
                          type="button"
                        >
                          Testes
                        </button>
                        <button
                          onClick={() => {
                            toggleActive(setApont, apont);
                            setId(ordem.id);
                          }}
                          className={`${
                            (ordem.status !== "a-fazer" ||
                              new Date(dia!) > new Date()) &&
                            "disabled"
                          } ${
                            (usuario.nivel === "visualizador" ||
                              !usuario.producao) &&
                            "unreachable"
                          }`}
                          disabled={
                            ordem.status !== "a-fazer" ||
                            new Date(dia!) > new Date()
                          }
                        >
                          Apontar
                        </button>
                        <button
                          onClick={() => {
                            setId(ordem.id);
                            toggleActive(setEdit, edit);
                          }}
                          className={`${
                            ordem.status !== "a-fazer" && "disabled"
                          } ${
                            (usuario.nivel !== "administrador" ||
                              !usuario.producao) &&
                            "unreachable"
                          }`}
                          disabled={ordem.status !== "a-fazer"}
                        >
                          Editar
                        </button>
                      </section>
                    </article>
                  );
                })
              ) : (
                <>
                  <article>Não há ordens programadas para o período!</article>
                  <button
                    onClick={() => toggleActive(setAdd, add)}
                    type="button"
                    className={`${
                      (!usuario.producao || usuario.nivel === "visualizador") &&
                      "unreachable"
                    }`}
                  >
                    Acrescentar ordens
                  </button>
                  <Adicionar
                    active={add}
                    dia={dia!}
                    fechar={() => toggleActive(setAdd, add)}
                  />
                </>
              )}
            </section>
            <section className={`${!navActive[1] && "unreachable"}`}>
              {tracoLoading ? (
                <Spinner active />
              ) : statusTraco === "success" ? (
                <section>
                  <form onSubmit={apontarConsumo} className="tracos">
                    <button
                      aria-hidden
                      className="unreachable"
                      type="submit"
                      disabled
                    ></button>
                    <section className="inputs">
                      {tracoData.map((traco) => {
                        return (
                          <section key={traco.tipo}>
                            <h2 style={{ fontWeight: "bold" }}>
                              Consumo de traço {traco.tipo.toUpperCase()}:
                            </h2>
                            <section>
                              <label htmlFor={`quantidade-${traco.tipo}`}>
                                Quantidade:
                              </label>
                              <input
                                min={0}
                                onChange={handleConsumoChange}
                                type="number"
                                name={`quantidade-${traco.tipo}`}
                                id={`quantidade-${traco.tipo}`}
                                disabled={
                                  dataConsumo && statusConsumo === "success"
                                }
                              />
                              <label htmlFor={`cimento-${traco.tipo}`}>
                                Cimento(kg):
                              </label>
                              <input
                                type="number"
                                min={0}
                                name={`cimento-${traco.tipo}`}
                                id={`cimento-${traco.tipo}`}
                                disabled
                              />
                              <label htmlFor={`eps-${traco.tipo}`}>
                                EPS (kg):
                              </label>
                              <input
                                min={0}
                                type="number"
                                name={`eps-${traco.tipo}`}
                                id={`eps-${traco.tipo}`}
                                disabled
                              />
                              <label htmlFor={`superplast-${traco.tipo}`}>
                                Super Plast (ml).:
                              </label>
                              <input
                                min={0}
                                type="number"
                                name={`superplast-${traco.tipo}`}
                                id={`superplast-${traco.tipo}`}
                                disabled
                              />
                              <label htmlFor={`incorpar-${traco.tipo}`}>
                                Incorp. Ar (ml):
                              </label>
                              <input
                                min={0}
                                type="number"
                                name={`incorpar-${traco.tipo}`}
                                id={`incorpar-${traco.tipo}`}
                                disabled
                              />
                            </section>
                          </section>
                        );
                      })}
                    </section>
                    <button
                      type="submit"
                      className={`${
                        dataConsumo && statusConsumo === "success" ? "disabled" : ""
                      } ${
                        (!usuario.producao ||
                          usuario.nivel === "visualizador") &&
                        "unreachable"
                      }`}
                      disabled={dataConsumo && statusConsumo === "success"}
                    >
                      Apontar Consumo
                    </button>
                  </form>
                </section>
              ) : (
                <article>Erro ao carregar dados de traço!</article>
              )}
            </section>
            <section className={`${!navActive[2] && "unreachable"}`}>
              {consumoLoading || resumoLoading ? (
                <Spinner active />
              ) : statusConsumo === "success" && statusResumo === "success" ? (
                <section className="inputs">
                  <section>
                    <label>
                      Cimento (t):
                      <input
                        defaultValue={(dataConsumo.cimento / 1000).toString()}
                        disabled
                        name="resumo-cimento"
                        id="resumo-cimento"
                      />
                    </label>
                    <label>
                      EPS (kg):
                      <input
                        defaultValue={dataConsumo.eps}
                        disabled
                        name="resumo-eps"
                        id="resumo-eps"
                      />
                    </label>
                    <label>
                      Super Plast (l).:
                      <input
                        defaultValue={(
                          dataConsumo.super_plast / 1000
                        ).toString()}
                        disabled
                        name="resumo-superplast"
                        id="resumo-superplast"
                      />
                    </label>
                    <label>
                      Incorp. Ar (l):
                      <input
                        defaultValue={(dataConsumo.incorp_ar / 1000).toString()}
                        disabled
                        name="resumo-incorpar"
                        id="resumo-incorpar"
                      />
                    </label>
                  </section>
                  <section>
                    <label>
                      Placas Ciment. 3,00m (ud):
                      <input
                        defaultValue={dataConsumo.placa_300}
                        disabled
                        name="resumo-placa300"
                        id="resumo-placa300"
                      />
                    </label>
                    <label>
                      Placas Ciment. 2,50m (ud):
                      <input
                        defaultValue={dataConsumo.placa_250}
                        disabled
                        name="resumo-placa250"
                        id="resumo-placa250"
                      />
                    </label>
                    <label>
                      Pallet 3,00m (ud):
                      <input
                        defaultValue={dataConsumo.pallet_300}
                        disabled
                        name="resumo-pallet300"
                        id="resumo-pallet300"
                      />
                    </label>
                    <label>
                      Pallet 2,50m (ud):
                      <input
                        defaultValue={dataConsumo.pallet_250}
                        disabled
                        name="resumo-pallet250"
                        id="resumo-pallet250"
                      />
                    </label>
                  </section>
                  <section style={{ width: "20dvw" }}>
                    <article style={{ display: "grid", gap: ".5rem" }}>
                      Painéis:
                      {dataResumo ? (
                        dataResumo
                          .filter((resumo) => resumo.quantidade > 0)
                          .map((resumo) => {
                            return (
                              <article
                                key={resumo.descricao}
                                className="coletas disabled"
                              >
                                <h3>{resumo.descricao}</h3>
                                <p>Quantidade: {resumo.quantidade}</p>
                              </article>
                            );
                          })
                      ) : (
                        <article></article>
                      )}
                    </article>
                  </section>
                </section>
              ) : (
                <article>
                  É necessário realizar o apontamento do consumo para verificar
                  o resumo!
                </article>
              )}
            </section>
          </section>
          <section className="nav">
            <section className="tabs">
              {navegacao.map((text, idx) => {
                return (
                  <button
                    key={text}
                    onClick={() => toggleList(setNav, navegacao, text)}
                    className={`tab ${navActive[idx] ? "in" : "out"}`}
                    type="button"
                  >
                    {text}
                  </button>
                );
              })}
            </section>
          </section>
        </section>
        <form
          onSubmit={handleAjuste}
          className={`ajustar-ordem center ${!edit && "unreachable"}`}
        >
          <button
            aria-hidden
            className="unreachable"
            type="submit"
            disabled
          ></button>
          <section className="input">
            <label htmlFor="tipoPainel">Tipo de Painel:</label>
            <select name="tipoPainel" id="tipoPainel">
              {proData && proData.length > 0 ? (
                proData.map((produto) => {
                  return (
                    <option key={produto.descricao} value={produto.descricao}>
                      {produto.descricao}
                    </option>
                  );
                })
              ) : (
                <option></option>
              )}
            </select>
          </section>
          <section className="input">
            <label htmlFor="qtdEdit">Quantidade:</label>
            <input type="number" name="qtdEdit" id="qtdEdit" min={1} required />
          </section>
          <button type="submit">Editar Ordem</button>
          <button className="close" onClick={() => toggleActive(setEdit, edit)}>
            <span className="icon">&#x2715;</span>
          </button>
        </form>
        <Apontamento
          dia={dia!}
          id={id}
          visible={apont}
          fechar={() => toggleActive(setApont, apont)}
        />
        <Testes
          id={id}
          tipo={tipo}
          visible={testes}
          fechar={() => toggleActive(setTestes, testes)}
        />
        <Atalhos />
        <Spinner active={spin} />
      </section>
    </>
  );

  async function iniciar(event: SyntheticEvent) {
    event.preventDefault();
    setSpin(true);

    try {
      await pcp.post(`/producao/ordens/iniciar/${event.currentTarget.id}`);

      window.location.reload();
      alert("Ordem de Produção iniciada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  function handleChange() {
    const dia = document.getElementById("dia") as HTMLInputElement;
    const status = document.getElementById("status") as HTMLSelectElement;
    const turno = document.getElementById("turno") as HTMLSelectElement;

    navigate(`/producao/ordens/${dia.value}/${turno.value}/${status.value}`);
  }

  async function handleAjuste(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const target = event.currentTarget;

    setSpin(true);

    try {
      await pcp.put(`/producao/ordens/${id}`, {
        tipo: target.tipoPainel.value,
        quantidade: target.qtdEdit.value,
      });

      window.location.reload();
      alert("Ordem de produção atualizada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function apontarConsumo(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSpin(true);
    const target = event.currentTarget;

    const baixaEstoque = {
      cimento:
        parseFloat(target["cimento-sp"].value) +
        parseFloat(target["cimento-2p"].value),
      eps:
        parseFloat(target["eps-sp"].value) + parseFloat(target["eps-2p"].value),
      super_plast:
        parseFloat(target["superplast-sp"].value) +
        parseFloat(target["superplast-2p"].value),
      incorp_ar:
        parseFloat(target["incorpar-sp"].value) +
        parseFloat(target["incorpar-2p"].value),
    };

    try {
      await pcp.post(`/producao/apontamento/consumo/${dia}`, baixaEstoque);

      window.location.reload();
      alert("Consumo apontado com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  function handleConsumoChange(event: SyntheticEvent) {
    const tipo = event.currentTarget.id.split("-").pop();

    const campoQtd = document.getElementById(
      `quantidade-${tipo}`
    ) as HTMLInputElement;
    const quantidade = parseInt(campoQtd.value);

    if (tracoData) {
      const traco = tracoData.find((traco) => traco.tipo === tipo);

      if (traco) {
        const campoCimento = document.getElementById(
          `cimento-${tipo}`
        ) as HTMLInputElement;
        campoCimento.value = (quantidade * traco.cimento).toString();

        const campoEPS = document.getElementById(
          `eps-${tipo}`
        ) as HTMLInputElement;
        campoEPS.value = (quantidade * traco.eps).toString();

        const campoSuperPlast = document.getElementById(
          `superplast-${tipo}`
        ) as HTMLInputElement;
        campoSuperPlast.value = (quantidade * traco.super_plast).toString();

        const campoIncorpAr = document.getElementById(
          `incorpar-${tipo}`
        ) as HTMLInputElement;
        campoIncorpAr.value = (quantidade * traco.incorp_ar).toString();
      }
    }
  }
};
