import { useState } from "react";

import { pcp } from "../../../Configs/Axios";

import { Spinner } from "../../../Components/Spinner/Spinner";
import { AxiosError } from "axios";

interface Props {
  id: number;
  visible: boolean;
  tipo: string;
  fechar(): void;
}

export const Testes = (props: Props) => {
  const [spin, setSpin] = useState<boolean>(false);

  return (
    <section className={`center ${props.visible ? "visible" : "invisible"}`}>
      <section>
        <form onSubmit={testes} className="apontamento">
          <button
            aria-hidden
            className="unreachable"
            type="submit"
            disabled
          ></button>
          <section className="input">
            <label htmlFor="flow">Flow (cm):</label>
            <input type="number" name="flow" id="flow" min={1} step={0.01} />
            <section className="input">
              <label htmlFor="densidade">Densidade (kg/m³):</label>
              <input
                type="number"
                name="densidade"
                id="densidade"
                min={1}
                step={0.01}
              />
            </section>
            <button type="submit">Apontar</button>
          </section>
        </form>
      </section>
      <section id="icon">
        <button className="close" onClick={props.fechar}>
          <span className="icon">&#x2715;</span>
        </button>
      </section>
      <Spinner active={spin} />
    </section>
  );

  async function testes(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    try {
      await pcp.post(`/qualidade/testes/${props.id}`, {
        flow: event.currentTarget.flow.value,
        densidade: event.currentTarget.densidade.value,
        tipo: props.tipo.toLowerCase(),
      });

      window.location.reload();
      alert("Produção apontada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
