export const usuario = ((): Colaborador => {
  const userData = localStorage.getItem("usuario");
  if (userData) return JSON.parse(userData);

  return {
    email: "",
    nome: "",
    nivel: "visualizador",
    unidade: "",
    logistica: false,
    producao: false,
    qualidade: false,
  };
})();
