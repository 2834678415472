import { useQuery } from "@tanstack/react-query";

import { Splash } from "../../../Components/Splash/Splash";

import { EficienciaProd } from "../Graficos/EficienciaProd";

import { fetchProdBaterias } from "../Helpers/fetch";

import { Spinner } from "../../../Components/Spinner/Spinner";

export const SplashProducao = () => {
  const { status, data, isLoading } = useQuery({
    queryKey: ["prodBaterias"],
    queryFn: fetchProdBaterias,
  });

  return (
    <Splash
      ativo={[true, false, false]}
      grafico={
        isLoading ? (
          <Spinner active />
        ) : status === "success" ? (
          <EficienciaProd title="Prod. Geral" dados={data} />
        ) : (
          <EficienciaProd
            title="Prod. Geral"
            dados={{
              producao: [],
              prod_max: [],
              prod_ideal: [],
              quantidade: [],
            }}
          />
        )
      }
      setor="producao"
      caminhos={[
        "baterias",
        `ordens/${new Date().toISOString().split("T")[0]}/1/a-fazer`,
        `estoque_produzido/${new Date().toISOString().split("T")[0]}`,
        `reparo/${new Date().toISOString().split("T")[0]}`,
      ]}
    />
  );
};
