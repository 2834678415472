import { SyntheticEvent, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { pcp } from "../../../Configs/Axios";

import { Spinner } from "../../../Components/Spinner/Spinner";

import { fetchEstoqueDisp } from "../../Logistica/Helpers/fetch";
import { AxiosError } from "axios";

interface Props {
  id: number;
  visible: boolean;
  fechar(): void;
}

export const DestinoReparo = (props: Props) => {
  const [dia, setDia] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [spin, setSpin] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ["estoqueDisp", dia],
    queryFn: () => fetchEstoqueDisp(dia),
  });

  return (
    <section className={`center ${props.visible ? "visible" : "invisible"}`}>
      <section>
        <form onSubmit={reparar} className="apontamento">
          <button
            aria-hidden
            className="unreachable"
            type="submit"
            disabled
          ></button>
          <section className="input">
            <label htmlFor="data"></label>
            <input
              onChange={handleDiaChange}
              type="date"
              name="data"
              id="data"
              defaultValue={dia}
            />
          </section>
          <section className="input">
            <label htmlFor="pallet">Pallet:</label>
            <select name="pallet" id="pallet">
              {data && data.length > 0 ? (
                data.map((pallet) => {
                  return (
                    <option key={pallet.id} value={pallet.id}>{`T${
                      pallet.turno
                    } ${pallet.referencia}/${
                      pallet.sequencial
                    } ${pallet.descricao.split("MM ").pop()}`}</option>
                  );
                })
              ) : (
                <option></option>
              )}
            </select>
          </section>
          <button type="submit">Apontar</button>
        </form>
      </section>
      <section id="icon">
        <button className="close" onClick={props.fechar}>
          <span className="icon">&#x2715;</span>
        </button>
      </section>
      <Spinner active={spin} />
    </section>
  );

  function handleDiaChange(event: SyntheticEvent) {
    event.preventDefault();

    const dia = event.currentTarget as HTMLSelectElement;
    setDia(dia.value);
  }
  async function reparar(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSpin(true);
    try {
      await pcp.patch(`/producao/reparo/${props.id}`, {
        pallet: event.currentTarget.pallet.value,
      });

      window.location.reload();
      alert("Painel reintegrado ao estoque!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) alert(error.response.data);

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
